@import './core/components/divider/Divider.css';

/* Place your extensions here to make migration to a new release easier. */
.exception-body {
	height: calc(100vh - 130px);
	min-height: 300px;
	font-size: 16px;
	margin: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background-position: top right;
	background-repeat: no-repeat;
	background-size: contain;
}

.exception-container {
	height: 100%;
	min-height: 240px;
	padding-left: 100px;
	padding-top: calc(50vh - 140px);
}

.exception-title {
	font-size: 80px;
	color: #424242;
	font-weight: normal;
	margin: 0;
}

.display-flex {
	display: flex;
}

/* Custom css */
body.p-overflow-hidden{
	position: inherit;
}
/*---Space define---*/
.p-space3{ height: 3px; width: 100%; float: left; }
.p-space5{ height: 5px; width: 100%; float: left; }
.p-space10{ height: 10px; width: 100%; float: left; }
.p-space15{ height: 15px; width: 100%; float: left; }
.p-space20{ height: 20px; width: 100%; float: left; }
.p-space30{ height: 30px; width: 100%; float: left; }
.p-space40{ height: 40px; width: 100%; float: left; }
.p-space50{ height: 50px; width: 100%; float: left; }
.p-space60{ height: 60px; width: 100%; float: left; }
.p-space70{ height: 70px; width: 100%; float: left; }
.p-space80{ height: 80px; width: 100%; float: left; }
.p-space90{ height: 90px; width: 100%; float: left; }
.p-space100{ height: 100px; width: 100%; float: left; }

/*---Margin define---*/
.p-margin-all-5 {margin: 5px!important;}
.p-margin-all-10 {margin: 10px!important;}
.p-margin-all-15 {margin: 15px!important;}
.p-margin-all-20 {margin: 20px!important;}
.p-margin-all-30 {margin: 30px!important;}

.p-margin-5-0 {margin: 5px 0px!important;}
.p-margin-10-0 {margin: 10px 0px!important;}
.p-margin-15-0 {margin: 15px 0px!important;}
.p-margin-20-0 {margin: 20px 0px!important;}
.p-margin-30-0 {margin: 30px 0px!important;}

.p-margin-0-5 {margin: 0px 5px!important;}
.p-margin-0-10 {margin: 0px 10px!important;}
.p-margin-0-15 {margin: 0px 15px!important;}
.p-margin-0-20 {margin: 0px 20px!important;}
.p-margin-0-30 {margin: 0px 30px!important;}

.p-margin-top-0 {margin-top: 0px!important;}
.p-margin-top-5 {margin-top: 5px!important;}
.p-margin-top-10 {margin-top: 10px!important;}
.p-margin-top-15 {margin-top: 15px!important;}
.p-margin-top-20 {margin-top: 20px!important;}
.p-margin-top-30 {margin-top: 30px!important;}

.p-margin-right-0 {margin-right: 0px!important;}
.p-margin-right-5 {margin-right: 5px!important;}
.p-margin-right-10 {margin-right: 10px!important;}
.p-margin-right-15 {margin-right: 15px!important;}
.p-margin-right-20 {margin-right: 20px!important;}
.p-margin-right-30 {margin-right: 30px!important;}

.p-margin-bottom-0 {margin-bottom: 0px!important;}
.p-margin-bottom-5 {margin-bottom: 5px!important;}
.p-margin-bottom-10 {margin-bottom: 10px!important;}
.p-margin-bottom-15 {margin-bottom: 15px!important;}
.p-margin-bottom-20 {margin-bottom: 20px!important;}
.p-margin-bottom-30 {margin-bottom: 30px!important;}

.p-margin-left-0 {margin-left: 0px!important;}
.p-margin-left-5 {margin-left: 5px!important;}
.p-margin-left-10 {margin-left: 10px!important;}
.p-margin-left-15 {margin-left: 15px!important;}
.p-margin-left-20 {margin-left: 20px!important}
.p-margin-left-25 {margin-left: 25px!important;}
.p-margin-left-30 {margin-left: 30px!important;}
.p-margin-left-35 {margin-left: 35px!important;}
.p-margin-left-200 {margin-left: 200px!important;}
.p-margin-left-100 {margin-left: 100px!important;}

.p-margin-auto {margin: auto!important;}
.p-margin-none {margin: 0px!important;}

/*---Padding define---*/
.p-padding-all-5 {padding: 5px!important;}
.p-padding-all-10 {padding: 10px!important;}
.p-padding-all-15 {padding: 15px!important;}
.p-padding-all-20 {padding: 20px!important;}
.p-padding-all-30 {padding: 30px!important;}

.p-padding-5-0 {padding: 5px 0px!important;}
.p-padding-10-0 {padding: 10px 0px!important;}
.p-padding-15-0 {padding: 15px 0px!important;}
.p-padding-20-0 {padding: 20px 0px!important;}
.p-padding-30-0 {padding: 30px 0px!important;}

.p-padding-0-5 {padding: 0px 5px!important;}
.p-padding-0-10 {padding: 0px 10px!important;}
.p-padding-0-15 {padding: 0px 15px!important;}
.p-padding-0-20 {padding: 0px 20px!important;}
.p-padding-0-30 {padding: 0px 30px!important;}

.p-padding-top-0 {padding-top: 0px!important;}
.p-padding-top-5 {padding-top: 5px!important;}
.p-padding-top-10 {padding-top: 10px!important;}
.p-padding-top-15 {padding-top: 15px!important;}
.p-padding-top-20 {padding-top: 20px!important;}
.p-padding-top-30 {padding-top: 30px!important;}

.p-padding-right-0 {padding-right: 0px!important;}
.p-padding-right-10 {padding-right: 10px!important;}
.p-padding-right-15 {padding-right: 15px!important;}
.p-padding-right-20 {padding-right: 20px!important;}
.p-padding-right-30 {padding-right: 30px!important;}

.p-padding-bottom-0 {padding-bottom: 0px!important;}
.p-padding-bottom-10 {padding-bottom: 10px!important;}
.p-padding-bottom-15 {padding-bottom: 15px!important;}
.p-padding-bottom-20 {padding-bottom: 20px!important;}
.p-padding-bottom-30 {padding-bottom: 30px!important;}

.p-padding-left-0 {padding-left: 0px!important;}
.p-padding-left-10 {padding-left: 10px!important;}
.p-padding-left-15 {padding-left: 15px!important;}
.p-padding-left-20 {padding-left: 20px!important;}
.p-padding-left-30 {padding-left: 30px!important;}

.p-padding-auto {padding: auto!important;}
.p-padding-none {padding: 0px!important;}

.p-line-5{display:block; height: 5px;}
.p-line-10{display:block; height: 10px;}
.p-line-15{display:block; height: 15px;}
.p-line-20{display:block; height: 20px;}
.p-line-25{display:block; height: 25px;}
.p-line-30{display:block; height: 30px;}

.p-hide {display: none!important;}

.p-label{
	display: block;
	font-weight: 600;
	opacity: 0.7;
	/* margin-bottom: 10px; */
}

.p-label-inline{
	display: inline-block;
	font-weight: 600;
	opacity: 0.7;
	/* margin-bottom: 10px; */
}

.p-hz-label{
	display: block;
	font-weight: 600;
	opacity: 0.7;
	padding-top: 2px;
	float: right;
}

.p-c{
	text-align: center;
}
.p-r{
	text-align: right;
}
.p-l{
	text-align: left;
}

.p-float-left{
	float: left;
}
.p-float-right{
	float: right;
}

.p-fieldset.p-fieldset-padding-sm{
	padding-left: 7.5px; 
	padding-right: 7.5px;
}

.p-fieldset-sm .p-fieldset-legend {
	padding:0!important;
}

.p-fieldset-no-legend .p-fieldset-legend {
	padding: 0!important;
	padding-inline-start: 0!important;
	padding-inline-end: 0!important;    
}

.p-fieldset-line{
	width: 105%;
	margin: 0 -0.5em;
	border-bottom: 1px solid #d8d8d8;
	height: 1px;
}

.p-hr{
	border-bottom: 1px solid #d8d8d8;
	display: block;
	font-size: 15px;
	font-weight: bold;
	padding: 5px 0;
}

.p-w-bold{
	font-weight: bold!important;
}
.p-w-normal{
	font-weight: normal!important;
}

.p-size-10{ font-size: 10px!important; }
.p-size-11{ font-size: 11px!important; }
.p-size-12{ font-size: 12px!important; }
.p-size-13{ font-size: 13px!important; }
.p-size-14{ font-size: 14px!important; }
.p-size-16{ font-size: 16px!important; }
.p-size-18{ font-size: 18px!important; }
.p-size-20{ font-size: 20px!important; }
.p-size-24{ font-size: 24px!important; }
.p-size-30{ font-size: 30px!important; }
.p-size-36{ font-size: 36px!important; }
.p-size-48{ font-size: 48px!important; }
.p-size-60{ font-size: 60px!important; }
.p-size-72{ font-size: 72px!important; }
.p-size-90{ font-size: 90px!important; }

.p-display-block {
	display: block;
}

.p-display-inline {
	display: inline;
}

.p-display-inline-block {
	display: inline-block;
}

/*-- Override CSS*/
.p-grid.form-group > div {
	padding: 0.6em;
}

body .p-inputtext{
	padding-bottom: 3px;
}

body .p-dropdown .p-dropdown-label {
	padding-bottom: 0px;
}

.p-dropdown .p-dropdown-label {
	white-space: pre-line;
}

.p-inputgroup .p-inputgroup-addon {
	min-width: fit-content;
}

.layout-wrapper .layout-main{
display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	transition: margin-left 0.2s;
	box-sizing: border-box;
}

.layout-wrapper .layout-sidebar{
	z-index: 999;
}

.layout-wrapper .layout-main .layout-topbar{
	z-index: 997;
}

.layout-wrapper .layout-main .layout-content{
	flex: 1 1 0;
}

.layout-wrapper .layout-sidebar .layout-menu li > a > .menuitem-text {
	max-width: 150px;
}

.p-form-error{
	color: #cc0000
}

.p-form-warning{
	color: #ffba01;
}

.p-sidebar-left.p-sidebar-lg, .p-sidebar-right.p-sidebar-lg{
	width: 90em;
}

.p-chips-token{
	padding: 3px 5px!important;
	margin-bottom: 5px!important;
	margin-right: 10px!important;
}

.p-chips > ul.p-inputtext .p-chips-input-token{
	margin-bottom: 5px!important;
	padding: 3px 5px 5px 5px!important;
	border: 1px solid #ddd;
	border-radius: 3px;
}

.p-nowrap {
	white-space: nowrap;
}
.p-lowercase {
	text-transform: lowercase;
}
.p-uppercase {
	text-transform: uppercase;
}
.p-capitalize {
	text-transform: capitalize;
}

.btn-sm {
	padding: 5px 10px!important;
	font-size: 12px!important;
	line-height: 1.5!important;
}

.btn-xs {
	padding: 1px 5px!important;
	font-size: 10px!important;
	line-height: 1.5!important;
}

.btn-text-sm {
	font-size: 12px!important;
	line-height: 1.5!important;
}

.btn-text-xs {
	font-size: 10px!important;
	line-height: 1.5!important;
}

.btn-s {
	padding: 1px 5px!important;
	font-size: 10px!important;
	line-height: 1.5!important;
	color:white!important;
	font-family: monospace!important;
}

.btn-s:hover {
	background-color: grey!important;
}

body .p-datatable .p-datatable-wrapper{
	overflow-x: scroll;
}

body .p-datatable .p-datatable-header h3.fle-datatable-header-title {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0.3em;
	color: #ffffff;
	font-weight: bold;
}

body .p-datatable .p-datatable-header .length-ctrl .p-menu{
	width: 100%;
	left: unset!important;
	right: 0px;
	text-align: center;
}
body .p-datatable .p-datatable-tbody > tr > td{
	overflow: hidden;
	text-overflow: ellipsis;
}

body .p-datatable .p-datatable-tbody > tr > td.tc-actions{
	overflow: visible;
	text-align: right;

	@media only screen and (min-width: 637px) {
			text-align: center;
	}

	.p-splitbutton > .p-menu {
		right: 0;
		left: unset!important;
		width: auto;

		.p-menuitem{
			white-space: nowrap;
		}
	}
}

body .p-button.p-button-constrast, body .p-buttonset.p-button-constrast > .p-button {
	background-color: #7CB342;
}

body .p-button.p-button-constrast:enabled:hover, body .p-buttonset.p-button-constrast > .p-button:enabled:hover {
	background-color: #5fa716;
}

body {
	.p-button{
		height: auto;
	}
	
	.p-dialog {
		.p-dialog-titlebar {
			border-bottom: 1px solid #dbdbdb;
		}

		.p-dialog-content {
			padding: 2rem 1rem;
			overflow: auto;
			max-height: calc(90vh - 120px);
			display: flex;
			align-items: center;

			.p-confirm-dialog-icon{
				font-size: 2rem;
			}

			.p-confirm-dialog-message{
				margin-left: 1rem;
			}
		}
	}
}

span:not(.p-checkbox-icon){
	&.pi{
		font-size: 1em!important;
	}

	&.pi.large{
		font-size: 1.5em!important;
	}
}

span.pi{
	&.p-dialog-titlebar-close-icon{
		font-size: 1.5em!important;
	}
}

body .p-growl{
	z-index: 9999!important;
	.p-growl-image{
		font-size: 1.5em!important;
	}
	.p-growl-icon-close::before{
		display: none;
	}
}

.p-menu-width-auto .p-menu{
	min-width: 12.5em;
	width: auto;
}

.p-datatable-length-selector .p-dropdown-trigger {
	color: #5fa716!important;
}

.p-datatable-length-selector .p-dropdown-items-wrapper {
	max-height: 1000px!important;
}

a.nav-link{
	text-decoration: underline;
}
a.nav-link:hover{
	color: #4b5ef1;
}

.p-priority{
	box-shadow: 0 0 0 1pt #ffffff;
}
.p-priority-1{
	border-left: 5px solid #37b96d;
}
.p-priority-2{
	border-left: 5px solid #2e87e0;
}
.p-priority-3{
	border-left: 5px solid #f7f134;
}
.p-priority-4{
	border-left: 5px solid #fca121;
}
.p-priority-5{
	border-left: 5px solid #e15139;
}

body .p-datatable-scp .p-datatable-footer{
	background-color: transparent;
	border-color: #cacaca;
}
body .p-datatable-scp .p-datatable-tbody > tr:nth-child(even){
	background-color: transparent;
}

button.p-button.p-component.p-button-warning.p-button-icon-only.cancel-button{
	position: absolute;
	z-index: 1;
	right: -13px;
	color: white;
	background-color: #ff8000;
	margin-top: -20px;
	margin-right: 12px;
	font-size: 9px;
	border: 1px solid #ff8000;
	box-shadow: none;
	top: 19px;
	border-radius: 0 0px 0px 10px!important;
}

ul.p-list-group{
	list-style-type: none;
	padding: 0;
	margin: 0;
}
ul.p-list-group li{
	margin: 5px 0px 10px 0px;
	border-bottom: 1px solid #d8d8d8;
}
ul.p-list-group li:last-child{
	border:0;
}

ul.p-list-search-custom{
	width: 400px!important;
	overflow-x: hidden;
	overflow-y: auto;
}
ul.p-list-search-custom:before{
	right: 3px;
	left: inherit!important;
}

@media (max-width: 1024px){
	ul.p-list-search-custom {
			width: 100%!important;
			overflow: auto;
	}
}

.p-fs-italic{
	font-style: italic;
}

.p-inputgroup .p-dropdown{
	min-width: auto!important;
}

/* .p-inputgroup .p-dropdown-label, .p-inputgroup .p-dropdown-trigger, .p-inputgroup .p-multiselect-label-container{
	margin-top: 8px!important;
} */

.p-inputgroup .p-dropdown-trigger, .p-inputgroup .p-multiselect-trigger{
	margin-right: 10px!important;
}

.p-inputgroup .p-checkbox, .p-inputgroup .p-radiobutton{
	margin: 0 0.5em 0 0;
}

.fa-stack{
	position: relative;
	display: inline-block;
	width: 2em;
	height: 2em;
	line-height: 2em;
	vertical-align: middle;
}

.fa-stack[data-count]:after{
	position:absolute;
	right:-20px;
	top:-10px;
	content: attr(data-count);
	font-size:80%;
	font-weight: bold;
	padding:.4em;
	border-radius:999px;
	line-height:.75em;
	color: #2e6da4;
	text-align:center;
	min-width:1em;
	font-weight:bold;
	background: white;
	border-style:solid;
}

.p-inputgroup .p-dropdown-async{
	background-image: none;
}

/*---logo---*/
.layout-wrapper:not(.layout-wrapper-static) .layout-sidebar:not(.layout-sidebar-active) .sidebar-logo a > img.app-logo{
	right: 5px;
	position: absolute;
}

.sidebar-logo a > span.app-name .p-dropdown{
	background-image: none;
}

.sidebar-logo a > span.app-name .p-dropdown .p-dropdown-trigger{
	height: 100%;
}

.p-promo-note{
	opacity: 0.7;
	font-size: 12px;
}

.p-multiselect .p-multiselect-label{
	white-space: unset;
}
.p-multiselect-panel .p-multiselect-item{
	white-space: unset;
}

.pointer {cursor: pointer;}

.node-label {
	display:inline-block;
	padding-left:8px;
	padding-right:8px;
	text-align:center;
	border-radius:4px;
	color:black!important;
	/* background-color:#4CAF50!important; */
}

.node-border {
	border:1px solid black!important;
	border-color:black!important
}

.rst__rowWrapper {
	height: 115%!important;
	width: 630px;
}

.react-dropdown-select-dropdown{
	width: 100%!important;
	top: 43px!important;
}

p:first-child{
	margin-top: 0
}

p:last-child{
	margin-bottom: 0
}

span.info-title{
	display: inline-block;
	width: 40%;
}

span.info-price{
	display: inline-block;
	min-width: 50%;
	text-align: right;
}

span.status{
	border-radius: 2px;
	padding: .25em .5em;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.completed, &.active, &.in_scheduled {
			background-color: #C8E6C9;
			color: #256029;
	}

	&.awaiting, &.processing {
			background-color: #f5d69e;
			color: #256029;
	}

	&.pending, &.inactive {
			background-color: rgb(252, 166, 166);
			color: #8A5340;
	}

	&.new_order {
		background-color: #B3E5FC;
		color: #23547B;
	}

	&.viewed, &.using {
			background-color: #ECCFFF;
			color: #694382;
	}

	&.ms-ACTIVE {
		background-color: #C8E6C9;
		color: #256029;
	}

	&.ms-BLOCK {
			background-color: #FFCDD2;
			color: #C63737;
	}

	&.ms-EXPIRED {
			background-color: #ECCFFF;
			color: #694382;
	}

	&.ms-PENDING {
			background-color: #FEEDAF;
			color: #8A5340;
	}

	&.ms-HOLDING {
			background-color: #FFD8B2;
			color: #805B36;
	}
}

.p-phoneinput{
	input{
		border: none;
	}

	input:focus{
		outline: none;
	}
}

.p-orderlist.p-orderlist-responsive{
	.p-orderlist-list-container{
		width: 95%;
	}
}

.p-splitbutton{
	.p-menu{
		right: 0;
		left: unset!important;
	}
}

body .p-steps{
	.p-steps-item{
		.p-menuitem-link{
			z-index: 1;
		}

		&.p-disabled{
			opacity: 0.5;
		}
		
		&::before{
			content: " ";
			border-top: 1px solid #c8c8c8;
			width: 100%;
			top: 50%;
			left: 0;
			display: block;
			position: absolute;
		}
	}
}

.product-item{
	height: 100%;
	
  .product-title{
    font-size: 20px;
    font-weight: bolder;
    margin: 0 0 10px 0;
  }

	.product-desc{
		display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
	}

  .product-price{
    font-size: 16px;
		font-weight: bold;
		margin: 0 0 10px 0;

		sup{
      line-height: 0;
      position: relative;
      vertical-align: baseline;
      font-size: 12px;
      top: -5px;
      margin-right: 0;
    }
  }
}

.p-sidebar-line{
	margin: 0 -14px;
	border-bottom: 1px solid #d8d8d8;
	height: 1px;
}

ul.p-inputtext.p-disabled li.p-chips-input-token {
	display: none !important;
}

span.react-dropdown-select-option span.react-dropdown-select-option-label{
	opacity: 1 !important;
}

.input-highlight{
	border: 1px solid #ff8000!important;
	margin: 1px 0px;
}

.labels-highlight{
	border-bottom: 2px solid  #ff8000!important;
}