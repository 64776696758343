.todo-enter {
    opacity: 0;
}
.todo-enter-active {
    opacity: 1;
    transition: opacity 300ms, transform 300ms;
}
.todo-exit {
    opacity: 1;
}
.todo-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}
