.card.wp {
    margin: 15px 15px 0px 15px;
}

.wp .p-breadcrumb {
    background-color: inherit !important;
    border: inherit !important;
    color: inherit !important;
}

.wp .p-breadcrumb ul li a {
    color: inherit !important;
}

.wp .p-breadcrumb ul li a span.active-step {
    color: white;
    background-color: #607D8B;
    padding: 5px;
}

body .p-steps:before {
    content: none;
}

.p-wf-steps .p-steps-item {
    width: inherit;
}

.p-wf-steps a.p-menuitem-link {
    text-align: center !important;
}

.p-wf-steps .step-finish {
    background-color: lightblue;
}