.todo-task-category {
    margin:"auto";
    margin-top: 7px;
    text-align: center; 
}

.todo-task-department {
    margin:"auto";
    width: "4vw";
}

.todo-task-detail {
    margin:"auto"!important;
    margin-left:5px!important;
}

.todo-task-btn-status {
    width:"0.5vw"!important;
    height:"3vh"!important;
    color:"black"!important;
    background-color: "white"!important;
    margin:"auto"!important;
    margin-left:0!important;
    margin-right:5!important;
}


