.topbar-profile-avatar {
    width: 36px!important;
    height: 36px!important;
    border-radius: 18px;
}

.li-notification {
    width:100%;
    height: 50px;
}

.notify-width {
    width: 25em!important;
    height: 20em!important;
    line-height: 2em;
    border: 1px solid #ccc;
    padding: 0;
    margin: 0;
    overflow: scroll;
    overflow-x: hidden;
    /* max-width: auto; */
}

.notify-width > li:is(:first-child){
    /* margin-top:10px; */
    border:none;
}

.fadeInDown::before {
    margin-left: 50px;;
}

.notify {
    width: 6px!important;
    height:auto!important;
    margin-top:8px!important;
}

.notify-icon-show {
    z-index:1;
}


.white {
    color:white!important;
}

.p-col-0.5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0.5em;
}

.p-col-0.5 {
    width: 4.1515%; 
}

