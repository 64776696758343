.relations{
    overflow: hidden;
}

.relations .p-panel-content {
    padding: 15px 0 10px 0 !important;
}

.relations ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

.relations ul li{
    border-bottom: 1px solid #d8d8d8;
    
}

.relations ul li > div {
    display: inline-block;
    padding: 9px;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    position: relative;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
}

.relations ul li > div img{
    float: left;
    width: 45px;
}

.relations ul li > div .profile-avatar{
    float: left;
    margin-left: 0;
}

.relations ul li > div > div{
    float: left;
    margin-left: 10px;
}

.relations ul li > div > div .name{
    font-size: 14px;
    font-weight: 700;
    display: block;
    color: #212121;
}

.relations ul li > div > div .relate{
    font-size: 14px;
    color: #757575;
}

.relations ul li > div button{
    position: absolute;
    top: 15px;
}

.relations ul li > div button.remove-btn{
    right: 60px
}

.relations ul li > div button.edit-btn{
    right: 20px
}