.login-body {
    height: 100vh;
}

.reset-password {
    margin-left:15px;
}

.recover-username {
    margin-left:15px;
    margin-top:3px;
    height:3vh;
}